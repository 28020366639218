import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import get from "lodash/get";
import { scale, rhythm } from "../utils/typography";
import Toggle from "./Toggle";
import moon from "../assets/moon.png";
import sun from "../assets/sun.png";

function Header({ location, theme }) {
  const data = useStaticQuery(headerQuery);
  const title = get(data, "site.siteMetadata.title");
  const rootPath = location.pathname === `${__PATH_PREFIX__}/`;

  return (
    <header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: rhythm(0.75)
      }}
    >
      <h3
        style={{
          fontFamily: "Montserrat, sans-serif",
          marginTop: 0,
          marginBottom: 0,
          height: 42,
          lineHeight: "2.625rem"
        }}
      >
        <Link
          to={"/"}
          style={{
            boxShadow: "none",
            textDecoration: "none",
            color: rootPath ? "var(--textTitle)" : "var(--primary)"
          }}
        >
          {title}
        </Link>
      </h3>
      {theme !== null ? (
        <Toggle
          icons={{
            checked: (
              <img
                src={moon}
                width="16"
                height="16"
                role="presentation"
                style={{ pointerEvents: "none" }}
              />
            ),
            unchecked: (
              <img
                src={sun}
                width="16"
                height="16"
                role="presentation"
                style={{ pointerEvents: "none" }}
              />
            )
          }}
          checked={theme === "dark"}
          onChange={e =>
            window.__setPreferredTheme(e.target.checked ? "dark" : "light")
          }
        />
      ) : (
        <div style={{ height: "24px" }} />
      )}
    </header>
  );
}

export const headerQuery = graphql`
  query HeaderQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Header;
