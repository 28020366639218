import React, { useState } from "react";
import { Link } from "gatsby";
import Toggle from "./Toggle";
import Helmet from "react-helmet";

import { rhythm, scale } from "../utils/typography";
import sun from "../assets/sun.png";
import moon from "../assets/moon.png";
import Header from "./Header";


class Layout extends React.Component {

    state = { theme: null };

    componentDidMount() {
        this.setState({ theme: window.__theme });
        window.__onThemeChange = () => {
            this.setState({ theme: window.__theme });
        };
    }

    render() {
        const { children, location } = this.props;
        return (
                <div
                    style={{
                        color: "var(--textNormal)",
                        background: "var(--bg)",
                        transition: "color 0.2s ease-out, background 0.2s ease-out",
                        minHeight: "100vh"
                    }}
                >
                    <Helmet
                        meta={[
                            {
                                name: "theme-color",
                                content: this.state.theme === "light" ? "#467fff" : "#282c35"
                            }
                        ]}
                    />
                    <div
                        style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            maxWidth: rhythm(24),
                            padding: `2.625rem ${rhythm(3 / 4)}`
                        }}
                    >
                        <Header location={location} theme={this.state.theme}/>
                        {children}
                    </div>
                </div>
        );
    }
}

export default Layout;